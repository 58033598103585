/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import Amplify, { API } from "aws-amplify";
import { graphql, useStaticQuery } from "gatsby";
import { Grommet } from "grommet";
import { CaretDownFill, FormCheckmark } from "grommet-icons";
import PropTypes from "prop-types";
import React from "react";
import "./layout.scss";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

const myTheme = {
  heading: {
    level: {
      1: {
        font: {},
        small: {
          size: "34px",
          height: "40px",
          maxWidth: "816px",
        },
        medium: {
          size: "50px",
          height: "56px",
          maxWidth: "1200px",
        },
        large: {
          size: "82px",
          height: "88px",
          maxWidth: "1968px",
        },
        xlarge: {
          size: "114px",
          height: "120px",
          maxWidth: "2736px",
        },
      },
      2: {
        font: {},
        small: {
          size: "26px",
          height: "32px",
          maxWidth: "624px",
        },
        medium: {
          size: "34px",
          height: "40px",
          maxWidth: "816px",
        },
        large: {
          size: "50px",
          height: "56px",
          maxWidth: "1200px",
        },
        xlarge: {
          size: "120px",
          height: "120px",
          maxWidth: "1584px",
        },
      },
      3: {
        font: {},
        small: {
          size: "22px",
          height: "28px",
          maxWidth: "528px",
        },
        medium: {
          size: "26px",
          height: "32px",
          maxWidth: "624px",
        },
        large: {
          size: "34px",
          height: "40px",
          maxWidth: "816px",
        },
        xlarge: {
          size: "42px",
          height: "48px",
          maxWidth: "1008px",
        },
      },
      4: {
        font: {},
        small: {
          size: "18px",
          height: "24px",
          maxWidth: "432px",
        },
        medium: {
          size: "18px",
          height: "24px",
          maxWidth: "432px",
        },
        large: {
          size: "18px",
          height: "24px",
          maxWidth: "432px",
        },
        xlarge: {
          size: "18px",
          height: "24px",
          maxWidth: "432px",
        },
      },
      5: {
        font: {},
        small: {
          size: "16px",
          height: "22px",
          maxWidth: "384px",
        },
        medium: {
          size: "16px",
          height: "22px",
          maxWidth: "384px",
        },
        large: {
          size: "16px",
          height: "22px",
          maxWidth: "384px",
        },
        xlarge: {
          size: "16px",
          height: "22px",
          maxWidth: "384px",
        },
      },
      6: {
        font: {},
        small: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
        medium: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
        large: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
        xlarge: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
      },
    },
  },
  global: {
    breakpoints: {
      xxsmall: { value: 630 },
      xsmall: { value: 890 },
      small: { value: 1120 },
      medium: { value: 1280 },
      large: { value: 1460 },
      xlarge: { value: 1860 },
    },
    font: {
      family: "AdihausDIN",
    },
    colors: {
      selected: "black",
    },
    focus: {
      border: {
        color: "transparent",
      },
    },
  },
  formField: {
    label: {
      margin: 0,
    },
  },
  checkBox: {
    border: {
      color: "black",
      width: "1px",
      radius: "none",
    },
    check: {
      radius: "0px",
      border: {
        color: "red",
      },
      extend: ({ theme, checked }) => `${checked && `background-color: black;`}`,
    },
    color: {
      light: "none",
      dark: "none",
    },
    gap: "xsmall",
    hover: {
      border: {
        color: undefined,
      },
    },
    icon: {
      size: "18px",
      extend: "stroke: white;",
    },
    icons: {
      checked: FormCheckmark,
    },
    size: "28px",
    extend: {
      backgroundColor: "white",
    },
  },
  layer: {
    zIndex: 999999,
  },
  select: {
    control: {
      open: {
        // height: 100,
      },
      extend: {
        height: 42,
        fontSize: "14px",
        fontWeight: 200,
        border: "none",
        borderRadius: "0",
        paddingTop: 0,
      },
    },
    options: {
      container: {
        // height: 100,
      },
      text: {
        size: "14px",
        fontWeight: 200,
      },
    },
    container: {
      extend: {
        padding: 0,
        height: 200,
        fontWeight: 200,
      },
    },
    step: 10,
    icons: {
      color: "black",
      down: CaretDownFill,
    },
  },
};

const Layout = ({ background = "white", children, fullHeight = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Grommet
      style={{
        position: "relative",
        height: fullHeight ? "100%" : null,
        backgroundColor: "black",
      }}
      theme={myTheme}>
      {children}
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
