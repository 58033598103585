import { Box } from "grommet"
import React from "react"
import FwdLogoInline from "../images/logo_4dfwd_white.inline.svg"
import AdidasLogoInline from "../images/logo_adidas_white.inline.svg"
import LogoSection from "../images/ui_bg_1.inline.svg"



const LogoBlock = ({ style, logo = "adidas", background="white" }) => {
  return (
    <Box height="55px" direction="row" style={style} flex={"grow"}>

      <Box
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto, auto",
        }}
        justify="center"
        align="center"
      >
        <AdidasLogoInline fill={background === "white" ? "black" : "white"} />
      </Box>
    </Box>
  )
}

export default LogoBlock
