import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { ResponsiveContext } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../helpers/events";


export default function useScrollPercentage() {
  const scrollRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(NaN);

  const reportScroll = (e) => {
    setScrollPercentage(getScrollPercentage(e.target));
  };

  useEffect(() => {
    const node = scrollRef.current;
    if (node !== null) {
      node.addEventListener("scroll", reportScroll, { passive: true });
      if (Number.isNaN(scrollPercentage)) {
        setScrollPercentage(getScrollPercentage(node));
      }
    }
    return () => {
      if (node !== null) {
        node.removeEventListener("scroll", reportScroll);
      }
    };
  }, [scrollPercentage]);

  return [scrollRef, Number.isNaN(scrollPercentage) ? 0 : scrollPercentage];
}

function getScrollPercentage(element) {
  if (element === null) {
    return NaN;
  }
  const height = element.scrollHeight - element.clientHeight;
  return Math.round((element.scrollTop / height) * 100);
}

export const usePageMargin = (inital) => {
  const size = useContext(ResponsiveContext);
  const [pageMargin, setPageMargin] = useState(inital);

  useEffect(() => {
    setPageMargin(size === "xxsmall" || size === "xsmall" ? 15 : 25);
  }, [size]);

  return [pageMargin];
};

const defaultState = { image: undefined, status: "loading" };

export const useImage = (url, crossOrigin) => {
  const res = useState(defaultState);
  const image = res[0].image;
  const status = res[0].status;

  const setState = res[1];

  useEffect(() => {
    if (!url) return;
    const img = document.createElement("img");

    function onload() {
      setState({ image: img, status: "loaded" });
    }

    function onerror() {
      setState({ image: undefined, status: "failed" });
    }

    img.addEventListener("load", onload);
    img.addEventListener("error", onerror);
    crossOrigin && (img.crossOrigin = crossOrigin);
    img.src = url;

    return () => {
      img.removeEventListener("load", onload);
      img.removeEventListener("error", onerror);
      setState(defaultState);
    };
  }, [url, crossOrigin]);

  // return array because it it better to use in case of several useImage hooks
  // const [background, backgroundStatus] = useImage(url1);
  // const [patter] = useImage(url2);
  return [image, status];
};

export const useProgressPoints = (script) => {
  let currentStage = useRef(0);

  const stage = useSelector((state) => state[script].stage);
  const actions = useSelector((state) => state[script].actions);
  const totalProgress = useSelector((state) => state.global.totalProgress[script]);
  const sceneNumber = useSelector((state) => state.global.sceneNumber);

  useEffect(() => {
    if (stage !== currentStage) {
      actions &&
        actions.map(({ ref, event, value = null }) => {
          if (ref === "progress") {
            setProgress({ script, totalProgress, sceneNumber });
          }
        });
      currentStage.current = stage;
    }
  }, [stage, actions, sceneNumber]);

  return totalProgress;
};

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const isSSR = typeof window === "undefined";

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    !isSSR && window.addEventListener("mousemove", setFromEvent);

    return () => {
      !isSSR && window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return position;
};

export const getWindowDimensions = () => {
  const isSSR = typeof window === "undefined";
  return {
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const isSSR = typeof window === "undefined";

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    !isSSR && window.addEventListener("resize", handleResize);
    return () => !isSSR && window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
