/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "adidasapi",
            "endpoint": "https://envn51f2e1.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "events",
            "endpoint": "https://c7aujat4e0.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "adidasusers-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "events-dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:fdfac821-0a66-46bd-a032-f3269e4a25cd",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_PdZk73Fm7",
    "aws_user_pools_web_client_id": "6rgj2vqqo08ibibci9hspj58vq",
    "oauth": {}
};


export default awsmobile;
