import Amplify, { API } from "aws-amplify"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { getUser } from "../services/auth"
export const isBrowser = () => typeof window !== "undefined"

export function logEvent(event, meta = {}) {
  const apiName = "events"
  const path = `/events`

  let eventDetails = {
    id: uuidv4(),
    createdAt: moment().format(),
    event,
    meta,
  }
  let user = getUser()
  const myInit = {
    body: {
      ...eventDetails,
      ...user,
    },
  }
  return API.post(apiName, path, myInit)
}

export const clearProgress = () => {
    window.localStorage.removeItem("adidasUserProgress");
}

export const setProgress = progress => {
  console.log("setPROGRESS FUNCTION", progress)
  window.localStorage.setItem("adidasUserProgress", JSON.stringify(progress))
}

export const getProgress = () =>
  isBrowser() && window.localStorage.getItem("adidasUserProgress")
    ? JSON.parse(window.localStorage.getItem("adidasUserProgress"))
    : {}
